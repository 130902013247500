<template>
  <div class="container">
    <div class="header">
      <Header></Header>
    </div>
    <div class="nav">
      <Nav :navData="navList" :isActive="nav_id"></Nav>
    </div>
    <div class="content">
      <div class="wCenter">
        <!-- 导航栏 -->
        <div class="navigation">
          <span class="index">首页 > </span>
          <span>关于我们 > </span>
          <span>{{ title }}</span>
        </div>
        <!-- 左侧 -->
        <div class="news-notice">
          <div class="news">
            <!-- <div class="info-title"></div>
            <div class="info-date"></div> -->
            <div class="info-content" v-html="content"></div>
          </div>
          <!-- 右侧 -->

          <div class="notices-right">
            <!-- 推荐阅读 -->
            <div class="notices">
              <div class="news-img">
                <img src="../assets/images/hot.png" alt="" />
                <router-link to="/news?news_id=9&nav_id=8"
                  ><div class="news-more">更多>></div></router-link
                >
                <div class="notices-title">推荐阅读</div>
              </div>
              <div class="title-list">
                <div
                  v-for="(hot_item, hot_index) in hot"
                  :key="hot_index"
                  class="title-item"
                  @click="jumpInfo(hot_item.id)"
                >
                  <div class="title">{{ hot_item.post_title }}</div>
                  <div class="date">
                    [{{ hot_item.create_time | formatDate }}]
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 底部 -->
    <div>
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import { getNav, getAbout, getMechanism } from "@/http/api/home/index";
import Header from "../components/header.vue";
import Nav from "../components/nav.vue";
import Footer from "../components/footer.vue";
export default {
  data() {
    return {
      id: "",
      navList: "",
      hot: [], //热门分类
      key: "",
      content: "",
      aboutData: [],
      nav_id: "",
      title: "",
    };
  },
  components: {
    Header,
    Nav,
    Footer,
  },
  watch: {
    $route: {
      handler: function (val) {
        this.getNav();
        var key = val.query.key;
        var id = val.query.id;
        this.nav_id = id;
        this.key = key;
        this.getAbout(key);
        this.getMechanism();
      },
    },
  },
  filters: {
    formatDate: function (value) {
      let date = new Date(value * 1000);
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      return MM + "-" + d;
    },
  },
  created() {
    this.getNav();
    var key = this.$route.query.key;
    var id = this.$route.query.id;
    this.nav_id = id;
    this.key = key;
    this.getAbout(key);
    this.getMechanism();
  },
  methods: {
    jump(query) {
      var url = "/news?" + query;
      this.$router.push({ path: url });
    },
    getNav() {
      getNav().then((res) => {
        this.navList = res.data.data;
      });
    },
    getAbout(key) {
      getAbout({ key: key }).then((res) => {
        this.content = res.data.data.content;
        this.aboutData = res.data.data.nav;
        var arr = this.aboutData;
        var href = "key=" + this.key;
        for (var i = 0; i < arr.length; i++) {
          if (arr[i]["href"] == href) {
            this.title = arr[i]["name"];
          }
        }
      });
    },
    getMechanism() {
      getMechanism({
        news_id: 9,
        nav_id: 2,
      }).then((res) => {
        this.hot = res.data.data.hot;
      });
    },
    jumpInfo(id) {
      var url = "/info?id=" + id + "&news_id=9&nav_id=8";
      this.$router.push({ path: url });
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  background: #f7f7f7;
  padding-top: 20px;
  padding-bottom: 20px;
  .navigation {
    height: 44px;
    background: #ffffff;
    font-size: 16px;
    color: #666666;
    line-height: 44px;
    padding-left: 11px;
    .index {
      cursor: pointer;
    }
  }
  .news-notice {
    width: 100%;
    display: flex;
    padding-top: 20px;
    .news {
      width: 800px;
      background: #ffffff;
      padding: 0 20px 20px 20px;
      .info-title {
        font-size: 24px;
        font-weight: 700;
        color: #333333;
        text-align: center;
        padding: 16px 0px;
        overflow: hidden;
      }
      .info-date {
        font-size: 18px;
        color: #666666;
        text-align: center;
      }
      .info-content {
        font-size: 18px;
        line-height: 34px;
        margin-top: 28px;
      }
    }
    .notices-right {
      width: 380px;
      height: auto;
      .notices {
        width: 380px;
        height: 429px;
        margin-left: 20px;
        background: #ffffff;
        padding: 20px;
        overflow: hidden;
        .news-img {
          width: 100%;
          position: relative;
          img {
            width: 100%;
            height: 100%;
          }
          .news-more {
            position: absolute;
            right: 0px;
            top: 10px;
            font-size: 12px;
            font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
            font-weight: 400;
            text-align: left;
            color: #005a69;
          }
          .notices-title {
            font-size: 16px;
            font-family: MicrosoftYaHei, MicrosoftYaHei-Bold;
            font-weight: 700;
            color: #ffffff;
            position: absolute;
            top: 5px;
            left: 18px;
          }
        }
        .title-list {
          cursor: pointer;
          .title-item {
            margin-top: 21px;
            width: 100%;
            height: 19px;
            display: flex;
            .title {
              width: 280px;
              font-size: 14px;
              font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
              color: #333333;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            .title:hover {
              color: #059e96;
            }
            .date {
              margin-left: 10px;
              font-size: 14px;
              font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
              color: #999999;
              line-height: 19px;
            }
          }
        }
      }
    }
  }
}
</style>
